<script>
export default {
  name: 'BgSwooshWhiteLeft',
  data() {
    return {
      isVisible: false,
    }
  },
  methods: {
    onVisibilityChange(entry) {
      this.isVisible = !this.animationBegun && entry.intersectionRatio * 100 > 0
    },
  },
}
</script>

<template>
  <svg
    v-voix-visibility="{ callback: onVisibilityChange, threshold: [0, 0.25] }"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 10 200 100"
    preserveAspectRatio="none"
    role="presentation"
    class="backface-none"
  >
    <g
      class="transition-all duration-[3000ms] ease-out"
      :class="{
        '-translate-x-24 opacity-0': !isVisible,
        '-translate-x-12 opacity-1': isVisible,
      }"
    >
      <path
        fill="#fff"
        d="M106,187.2h91c0,0-19.5-6-48.3-81.4S93,12,93,12H2c0,0,42.1,33.4,56.2,94.7S106,187.2,106,187.2z"
      />
    </g>
  </svg>
</template>
